<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Détails de l'événement" size="lg">
        <div class="text-center">
        <evenement-infos :evenement="evenement" :editable="false"/>
        <b-button @click="hideModal">Fermer</b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    const EvenementInfos = () => import('@/components/evenement/EvenementInfos');

    export default {
        name: "DisplayInfosEvenementModal",
        components: {EvenementInfos},
        mixins: [modalMixin],
        props: {
            evenement: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modalRef: 'displayInfosModal'
        })
    }
</script>
